<template>
    <v-container fluid class="mx-0 px-3">
        <v-card flat class="avsb-card-background">
            <v-card-title class="pl-1 py-2">Trackers</v-card-title>
        </v-card>

        <v-card elevation="0" outlined>
            <v-card-title>
                <v-row dense>
                    <v-col cols="12" sm="12" md="4">
                        <div>
                            <CustomTextField
                                clearable
                                id="search"
                                inputIcon="mdi-magnify"
                                inputPlaceholder="Search..."
                                :inputValue="search"
                            />
                        </div>
                    </v-col>

                    <v-col cols="12" sm="12" md="8">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="2">
                                <!-- Today button -->
                                <CustomButton
                                    :block="$vuetify.breakpoint.smAndDown"
                                    btnColour="primary"
                                    btnLabel="Today"
                                    tooltipMsg="Go To Today"
                                    @click="setToday"
                                />
                            </v-col>

                            <v-col cols="6" sm="6" md="2">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            class="avsb-button"
                                            depressed
                                            v-on="on"
                                            v-bind="attrs"
                                            width="100px"
                                        >
                                            <span>{{ typeToLabel[type] }}</span>
                                            <v-icon right>mdi-menu-down</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item @click="type = 'day'">
                                            <v-list-item-title
                                                >Day</v-list-item-title
                                            >
                                        </v-list-item>

                                        <v-list-item @click="type = 'week'">
                                            <v-list-item-title
                                                >Week</v-list-item-title
                                            >
                                        </v-list-item>

                                        <v-list-item @click="type = 'month'">
                                            <v-list-item-title
                                                >Month</v-list-item-title
                                            >
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>

                            <v-col cols="6" sm="6" md="2">
                                <!-- Left chevron button -->
                                <CustomButton
                                    btnIcon="mdi-chevron-left"
                                    fab
                                    small
                                    tooltipMsg="Previous"
                                    @click="cyclePrevious"
                                />

                                <!-- Right chevron button -->
                                <CustomButton
                                    btnIcon="mdi-chevron-right"
                                    fab
                                    small
                                    tooltipMsg="Next"
                                    @click="cycleNext"
                                />
                            </v-col>

                            <v-col cols="12" md="2">
                                <!-- Add button -->
                                <CustomButton
                                    :block="$vuetify.breakpoint.smAndDown"
                                    btnColour="primary"
                                    btnLabel="Add Tracker"
                                    tooltipMsg="Add Tracker"
                                    @click="showCreateDialog"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-row dense>
                    <v-col>
                        <div v-if="$refs.calendar">
                            <h2>{{ $refs.calendar.title }}</h2>
                        </div>
                    </v-col>
                </v-row>

                <v-row dense class="fill-height">
                    <v-col class="fill-height">
                        <v-sheet>
                            <v-calendar
                                color="primary"
                                event-end="datetime_end"
                                event-start="datetime_start"
                                :events="trackers"
                                interval-height="50"
                                interval-minutes="60"
                                ref="calendar"
                                :short-intervals="false"
                                :type="type"
                                v-model="focusDate"
                                :weekdays="weekdays"
                                @click:date="viewDay"
                                @click:event="viewItem"
                                @click:more="viewMore"
                                @change="retrieveItems"
                            >
                                <template v-slot:event="{ event }">
                                    <span class="pl-1">
                                        <strong>{{
                                            formatCalendarTime(
                                                event.datetime_start
                                            )
                                        }}</strong>
                                        {{ event.customer.name }}</span
                                    >
                                </template>
                            </v-calendar>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- Dialogs -->
        <DialogTrackerCreate />
        <DialogTrackerView :tracker="tracker" />
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { DateTime } from "luxon";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});
export default {
    computed: {
        ...mapState("trackersUserMgmt", ["createOps", "trackers"]),
    },
    components: {
        DialogTrackerCreate: () =>
            import("@/components/user/dialogs/dialogTrackerCreate"),
        DialogTrackerView: () =>
            import("@/components/user/dialogs/dialogTrackerView"),
    },
    data: () => ({
        focusDate: "",
        search: "",
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        tracker: null,
        type: "week",
        typeToLabel: {
            month: "Month",
            week: "Week",
            day: "Day",
        },
        weekdays: [1, 2, 3, 4, 5, 6, 0],
    }),

    methods: {
        cycleNext() {
            this.$refs.calendar.next();
        },
        cyclePrevious() {
            this.$refs.calendar.prev();
        },
        formatCalendarTime(dateTime) {
            let parsed = DateTime.fromSQL(dateTime);
            return parsed.toFormat("h:mm a");
        },
        formatDateTime(dateTime) {
            return DateTime.fromISO(dateTime).toFormat("yyyy-LL-dd HH:mm");
        },
        retrieveItems(dateRange) {
            this.$store.dispatch("trackersUserMgmt/retrieveItems", dateRange);
        },
        setToday() {
            this.focusDate = "";
        },
        showCreateDialog() {
            this.$store.commit("trackersUserMgmt/SHOW_CREATE_OPS_DIALOG");
        },
        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                requestAnimationFrame(() =>
                    requestAnimationFrame(() => (this.selectedOpen = true))
                );
            };

            if (this.selectedOpen) {
                this.selectedOpen = false;
                requestAnimationFrame(() =>
                    requestAnimationFrame(() => open())
                );
            } else {
                open();
            }

            nativeEvent.stopPropagation();
        },
        viewDay({ date }) {
            this.focusDate = date;
            this.type = "day";
        },
        viewItem(event) {
            this.$store.commit("trackersUserMgmt/POPULATE_ITEM", {
                form: "tracker",
                item: event.event,
            });
            this.$store.commit("trackersUserMgmt/SHOW_VIEW_OPS_DIALOG");
        },
        viewMore({ date }) {
            this.focusDate = date;
            this.type = "day";
        },
    },
    name: "CalendarView",
    watch: {
        search(term) {
            console.log(term);
        },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

